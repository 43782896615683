import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import '../../global.css'
import favicon from '../../favicon.png'
import Nav from '../../navigation.js'
import Footer from '../../footer.js'

import carsBg from '../../carsBg.jpg'

var splendoBlue = '#2F76CA'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  .sectionHeader {
    font-size: 1.5em;
    padding: 30px 0;
  }
`

const Hero = styled.header`
  text-align: left;
  position: relative;
  height: 50%;
  background-image: url(${carsBg});
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    text-align: center;
  }
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 600px;
    padding-left: 10%;
    transform: translateY(-50%);
    @media (max-width: 800px) {
      padding: 0;
    }
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
    }
  }
`
const Details = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 800px) {
    text-align: center;
  }
  h1 {
    padding-bottom: 30px;
  }

  p {
    font-family: nunitoReg, sans-serif;
    padding: 0 0 40px;
    width: 90%;
    max-width: 800px;
    @media (max-width: 800px) {
      font-size: 1em;
      text-align: center;
      width: 100%;
    }
  }

  a {
    display: inline-block;
    padding: 20px 40px;
    color: white;
    border-radius: 12px;
    background: ${splendoBlue};
    text-transform: uppercase;
  }
`
const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export default () => (
  <Container>
    <Wrapper>
      <Helmet>
        <title>Splendo</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Splendo" />
        <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>

      <Nav />

      <Hero>
        <div className="wrapper">
          <h1>X.bike: flexible smart bike platform</h1>
        </div>
      </Hero>

      <Details>
        <h1 className="sectionHeader">About X.bike</h1>
        <p>
          Splendo built the native Android and iOS apps and cloud for X.bike's modular white-label smart bike platform. It allows clients to setup and run any type of bike share or rental operation with the industry leading AxA E-RL smart locks.
        </p>
        <p>
          X.bike contacted Splendo to move their product to native Android and iOS to improve Bluetooth stability and overall performance. The apps we built turned out to be the most reliable option for the AxA E-RL lock. Which allowed X.bike to pivot from small scale bike sales to large scale white-label licensing.
        </p>

        <a href="https://x.bike" target="_blank" rel="noopener noreferrer">
          View Website
        </a>
      </Details>

      <Footer />
    </Wrapper>
  </Container>
)
